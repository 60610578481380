import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { Button, Menu, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(message.body);

  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const hanldeReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };

  const handleEditMessage = () => {
    setIsEditing(true);
    handleClose();
  };

  const handleSaveEdit = async () => {
    try {
      await api.put(`/editmessage/`, { messageId: message.id, newBody: editedText });
      setIsEditing(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedText(message.body);
  };

  return (
    <>
      {/* Modal para editar mensagem */}
      <Dialog open={isEditing} onClose={handleCancelEdit} maxWidth="sm" fullWidth>
        <DialogTitle>{i18n.t("Editar Mensagem")}</DialogTitle>
        <DialogContent>
          <TextField
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            label={i18n.t("Editar Mensagem")}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit} color="secondary">
            {i18n.t("Cancelar")}
          </Button>
          <Button onClick={handleSaveEdit} color="primary" variant="contained">
            {i18n.t("Salvar")}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {message.fromMe && (
          <MenuItem onClick={handleOpenConfirmationModal}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
        {message.fromMe && (
          <MenuItem onClick={handleEditMessage}>
            {i18n.t("Editar")}
          </MenuItem>
        )}
        <MenuItem onClick={hanldeReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MessageOptionsMenu;
